import Post from "@components/common/Post";
import styled from "@emotion/styled";
import { XIcon } from "@primer/octicons-react";
import { graphql, useStaticQuery } from "gatsby";
import { useEffect, useRef, useState } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  slug: string;
}

export const Modal = ({ isOpen, onClose, slug }: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [isHover, setIsHover] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            slug
            title
            date
            category
          }
          html
        }
      }
    }
  `);
  const post = data.allMarkdownRemark.nodes.find(
    (node: any) => node.frontmatter.slug === slug,
  );
  const handleOverlayClick = () => {
    setIsHover(true);
    setTimeout(() => {
      setIsHover(false);
    }, 350);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault();
      if (isOpen) {
        onClose();
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  if (!isOpen) return null;
  return (
    <Overlay onClick={handleOverlayClick}>
      <StyleModal onClick={e => e.stopPropagation()} ref={modalRef}>
        <StyleCloseButtonBox>
          <StyleCloseButton isHover={isHover} onClick={onClose}>
            <XIcon size={16} />
          </StyleCloseButton>
        </StyleCloseButtonBox>
        <Post frontmatter={post.frontmatter} html={post.html} inner />
      </StyleModal>
    </Overlay>
  );
};

const StyleModal = styled.div`
  position: absolute;
  max-width: 1000px;
  min-width: 380px;
  width: 70%;
  @media (max-width: 768px) {
    width: 90%;
  }
  height: 95%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 6px;
  outline: none;
  padding: 20px;
  z-index: 1001;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
`;

const StyleCloseButtonBox = styled.div`
  position: sticky;
  width: 30px;
  height: 30px;
  top: 10px;
  left: 95%;
  display: flex;
  justify-content: flex-end;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  border-radius: 50%;
`;

const StyleCloseButton = styled.button<{ isHover: boolean }>`
  border: none;
  border-radius: 50%;
  background-color: white;
  width: 30px;
  height: 30px;
  font-size: 1.5rem;
  line-height: 1.5rem;
  cursor: pointer;
  padding: 0;
  ${({ isHover }) =>
    isHover
      ? `
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
      transition: 0.3s;
    `
      : `
      background-color: white;
      color: black;
      transition: 0.3s;
    `}
  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    transition: 0.3s;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  }
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;
