import React, { ReactNode } from "react";
import ModalsProvider from "./src/components/Modal/ModalProvider";
require("prismjs/themes/prism-okaidia.css");
require("prismjs/plugins/line-numbers/prism-line-numbers.css");
require("prismjs/plugins/command-line/prism-command-line.css");
require("./src/assets/prism-custom.css");

export const wrapRootElement = ({ element }: { element: ReactNode }) => {
  return <ModalsProvider>{element}</ModalsProvider>;
};
