import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { FRONTMATTER } from "@pages/blog/{markdownRemark.frontmatter__slug}";
interface PostProps {
  frontmatter: FRONTMATTER;
  html: string;
  inner?: boolean;
}
const Post = ({ frontmatter, html, inner = false }: PostProps) => {
  return (
    <GlobalStyle inner={inner}>
      <StyleContainer inner={inner}>
        {!inner && (
          <div>
            <StyleInfoContainer>
              <StyleHeaderContainer>
                <StyleCategory>[{frontmatter.category}]</StyleCategory>
                <StyleTitle>{frontmatter.title}</StyleTitle>
              </StyleHeaderContainer>
              <StyleDate>{frontmatter.date}</StyleDate>
            </StyleInfoContainer>
          </div>
        )}
        <hr />
        <StyleContent dangerouslySetInnerHTML={{ __html: html }} />
        <hr />
      </StyleContainer>
    </GlobalStyle>
  );
};

export default Post;
interface InnerProps {
  inner: boolean;
}
const StyleContainer = styled.div<InnerProps>`
  width: 100%;
  padding: 24px 24px;
  @media (max-width: 612px) {
    font-size: 60%;
    padding: 18px 12px;
  }
  @media (max-width: 500px) {
    font-size: 55%;
    padding: 12px 0px;
  }
  /* margin-left: 10rem; */ // ToC때문에 추가했는데, Modal에서도 깨지는 현상 발생해서 주석처리
  font-family: var(--font-sans);
`;
const StyleHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyleTitle = styled.span`
  font-size: 4.6rem;
  font-weight: 700;
  @media (max-width: 612px) {
    font-size: 4rem;
  }
`;
const StyleInfoContainer = styled.span`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
`;
const StyleCategory = styled.span`
  font-size: 1.5rem;
  font-weight: 400;
  font-family: monospace;
`;
const StyleDate = styled.span`
  font-size: 1.7rem;
  font-weight: 400;
`;

const StyleContent = styled.div`
  font-size: 1.7rem;
`;

const GlobalStyle = styled.div<InnerProps>`
  word-break: keep-all;
  color: var(--color-black);
  h1 {
    font-size: 3rem;
    ${({ inner }) =>
      inner &&
      css`
        font-size: 3rem;
      `}
  }
  h2 {
    font-size: 2.75rem;
    ${({ inner }) =>
      inner &&
      css`
        font-size: 2.5rem;
      `}
  }
  h3 {
    font-size: 2.5rem;
    ${({ inner }) =>
      inner &&
      css`
        font-size: 2rem;
        margin: 2rem 0 0 0;
      `}
  }
  hr {
    margin: 2rem 0;
  }
  code {
    font-size: 1.7rem;
  }
  ul {
    margin-top: 0.4rem;
  }
  li {
    margin-bottom: 0.4rem;
  }
  a {
    font-weight: bold;
  }
  figcaption {
    margin: 8px 0;
    color: var(--color-gray-500);
    text-align: center;
    font-size: 1.6rem;
  }
  .image_container {
    margin: 1rem 0 2rem 0;
  }
  .image_description_container {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .image_description {
    font-size: 1.5rem;
    color: var(--color-gray-500);
  }
  .image_description.center {
    text-align: center;
  }
  .image-wrapper {
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
    margin: 0 auto;
    pointer-events: none;
  }
  .image-wrapper.full {
    width: 100%;
  }
  .column-split {
    display: flex;
    gap: 1rem;
  }
  .info {
    display: flex;
    flex-direction: column;
  }
  .stacks {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
    flex-wrap: wrap;
  }
  .stack {
    margin: 0;
    display: inline-block;
    padding: 0.5em 0.7em;
    font-size: 1.2rem;
    font-weight: 400;
    font-family: monospace;
    line-height: 1.4rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 5px;
    color: #fff;
    background-color: #009dff;
  }
`;
